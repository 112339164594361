import DecorBlob from "@/Components/Decor/DecorBlob.jsx";
import DecorContainer from "@/Components/Decor/DecorContainer.jsx";
import DecorOverflow from "@/Components/Decor/DecorOverflow.jsx";
import { useLaravelReactI18n } from "laravel-react-i18n";
import ContactsForm from "./ContactsForm";

function LocationCard() {
    const { t } = useLaravelReactI18n();

    const locationCardData = [
        {
            title: t("site.locationCardData.cardTitle1"),
            icon: require("@images/pages/home/contact__location-icon-1.svg")
                .default,
            address: t("site.locationCardData.cardAddress1"),
        },
        {
            title: t("site.locationCardData.cardTitle2"),
            icon: require("@images/pages/home/contact__location-icon-2.svg")
                .default,
            address: t("site.locationCardData.cardAddress2"),
        },
        {
            title: t("site.locationCardData.cardTitle3"),
            icon: require("@images/pages/home/contact__location-icon-3.svg")
                .default,
            address: t("site.locationCardData.cardAddress3"),
        },
    ];

    return (
        <div className={"location-cards"}>
            {locationCardData.map((data, index) => (
                <div className={"location-card"} key={index}>
                    <div className={"location-card__head"}>
                        <p className={"location-card__head-title"}>
                            {data.title}
                        </p>
                        <div className={"location-card__head-preview"}>
                            <img src={data.icon} alt="address-icon" />
                        </div>
                    </div>
                    <div
                        className={"location-card__address"}
                        dangerouslySetInnerHTML={{ __html: data.address }}
                    ></div>
                </div>
            ))}
        </div>
    );
}

const Footer = ({ pageProps }) => {
    const { t } = useLaravelReactI18n();

    return (
        <>
            <section
                className={"contact container mb-80px ph:mb-[160px]"}
                id="contacts"
            >
                <div className={"container__inner"}>
                    <div className={"contact__row"}>
                        <div className={"contact__text"}>
                            <h2
                                className={"mb-48px ph:mb-20px"}
                                id={"contact"}
                                dangerouslySetInnerHTML={{
                                    __html: t("site.contactBlock.subTitle"),
                                }}
                            ></h2>
                            <p
                                className={
                                    "contact__subtitle text-[24rem] max-w-[380px] font-medium"
                                }
                                dangerouslySetInnerHTML={{
                                    __html: pageProps.settings.find(
                                        (el) => el.key === "contacts",
                                    )?.value,
                                }}
                            ></p>
                        </div>
                        <ContactsForm locale={pageProps.locale} />
                    </div>
                </div>
            </section>

            <section className={"get-involved container pb-80px ph:pb-100px"}>
                <div className={"container__inner"}>
                    <div className={"get-involved__row mb-120px ph:mb-30px"}>
                        <div className={"get-involved__row-content max-w-[533px]"}>
                            <p
                                className={
                                    "text-[40rem] mb-[40px] font-extrabold"
                                }
                            >
                                {t("site.footerBlock.subTitle")}
                            </p>
                            <p
                                className={
                                    "text-[30rem] font-normal tracking-[-1px]"
                                }
                                dangerouslySetInnerHTML={{__html: t("site.footerBlock.text")}}
                            >
                            </p>
                        </div>
                        <div className={"get-involved__reward"}>
                            <div className={'flex gap-24px ph:flex-wrap ph:max-w-[390px] ph:justify-evenly ph:items-center ph:gap-15px'}>
                                <img
                                    className={'max-w-[134px] w-[100%] ph:max-w-[138px] ph:h-auto'}
                                    src={require("@images/pages/home/award-1.svg")}
                                    alt="award-1"
                                />
                                <img
                                    className={'max-w-[180px] w-[100%] ph:max-w-[138px] ph:h-auto'}
                                    src={require("@images/pages/home/award-2.png")}
                                    alt="award-1"
                                />
                                <img
                                    className={'max-w-[180px] w-[100%] ph:max-w-[138px] ph:h-auto'}
                                    src={require("@images/pages/home/award-3.svg")}
                                    alt="award-1"
                                />
                                <img
                                    className={'max-w-[178px] w-[100%] ph:max-w-[138px] ph:h-auto'}
                                    src={require("@images/pages/home/award-4.png")}
                                    alt="award-1"
                                />
                            </div>

                        </div>
                    </div>
                    <div className={'flex justify-between items-center gap-30px mb-34px mt-40px ph:flex-col'}>
                        <div className={"flex flex-col gap-[28px]"}>
                            <div
                                className={"flex gap-[14px] ph:justify-center"}
                            >
                                <a
                                    href="https://www.facebook.com/share/cQNAehFC7Qeg2SVz/?mibextid=LQQJ4d"
                                    target="_blank"
                                >
                                    <img
                                        src={require("@images/pages/home/contact__icon-fb.png")}
                                        alt="fb"
                                        width="39.5"
                                    />
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/vivantieurope"
                                    target="_blank"
                                >
                                    <img
                                        src={require("@images/pages/home/contact__icon-in.png")}
                                        alt="in"
                                        width="39.5"
                                    />
                                </a>
                            </div>
                            <p>
                                Vivanti<sup>©</sup> 2014-2024
                            </p>
                        </div>
                        <div className={"get-involved__row-content ph:order-2"}>
                            <div className={"flex justify-end gap-[37px] text-[24rem] ph:mt-30px ph:text-center ph:justify-center"}>
                                <a
                                    href="/page/privacy-statement"
                                    target="_blank"
                                    className={"underline hover:no-underline"}
                                >
                                    {t("site.footerBlock.footerPrivacy")}
                                </a>
                                <a
                                    href="/page/terms-and-conditions"
                                    target="_blank"
                                    className={"underline hover:no-underline"}
                                >
                                    {t("site.footerBlock.footerTermOfUse")}
                                </a>
                            </div>
                        </div>
                    </div>
                    <LocationCard></LocationCard>
                </div>
                <DecorOverflow>
                    <DecorContainer>
                        <DecorBlob
                            typeNum={4}
                            width={1168.19}
                            blur={150}
                            rotate={-49.21}
                            opacity={1}
                            x={-74}
                            yBottom={13}
                        />
                        <DecorBlob
                            typeNum={4}
                            width={1168.19}
                            blur={150}
                            rotate={81.61}
                            opacity={1}
                            x={136}
                            yBottom={53}
                        />
                    </DecorContainer>
                </DecorOverflow>
            </section>
        </>
    );
};

export default Footer;
